/* Styling for body, modal and observation markers, and panels (but not the right hand panel - see Sidebar.css for that)  */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:rgb(104, 103, 105)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title {
  text-align: center;
}

h2, h3, h4, h5 {
  color:rgb(128, 128, 133);
  margin-left:10px; 
}

h7 {
  color:rgb(128, 128, 133);
  font-size: 11.5px;
  font-style: italic;  
  /* line-height: 0.5; */
  margin-bottom:1px;
}

p {
  margin-bottom: 1px;
}

.modal {cursor: crosshair;}

.leaflet-container {
  height: 650px;
  cursor: crosshair;
}

/* AwesomeFont marker for the observations (the marker is actually text) */
.leaflet-div-icon {
  background: transparent;
  border: none;
  font-size:16pt;
}  

/* Split screen (this uses flex )*/
.splitScreen {
  display: flex;
  flexDirection: row;
  z-index: 2;
}


.rightPane {
  width: 100%;
}

/* for any text above map, set these values to make space */
#page-wrap {
  padding-bottom: 0px;
  padding-top: 0px;
}

/* Panel on right hand side */
* {
  box-sizing: border-box;
}

.leftPane {
  width: 23%;
  background: rgba(175, 220, 238, 0.3);
}

.leftPaneContents{
  padding-left:7px;
  padding-right:7px;
  margin-right:12px;
  margin-left:12px;
}


/* div on Panel on LHS containing the 3 filters;  Display All button */
.filterForm  .displayButton{
  padding-left:7px;
  padding-right:7px;
  margin-right:7px;
  margin-left:7px;
  text-align: left; 
}

.clearFilterBtn{
  display: inline;
  margin-top:2px;
}

.form-control{
  margin-right:7px;
  margin-left:7px;
  padding-left:7px;
  padding-right:7px;
} 

.speciesForm .observerForm .iDForm {
  margin-right:7px;
  margin-left:7px;
}

/* font size in the forms on LH panel*/
select#organisms.form-control, select#observer.form-control , select#ids.form-control {
  font-size: 13px;
}


/* needed for table word wrap to work (required in RH panel table)*/
* { 
  word-wrap:break-word;                   
} 

/* Table of observations in the RH panel */
table { 
  table-layout:fixed;
  width:100%;
  padding: 1px;
  line-height: 1.2;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);

}

tr:hover{
  color:white;
}

tr {text-align:center; color: rgb(10, 10, 10)}
#xnote {text-align:left;} 


.bottom_border1{
  border-bottom: 0.5px solid rgb(131, 130, 130);
}

.bottom_border2{
 border-bottom: 0.5px rgba(175, 220, 238, 0.3);
}


/* The legend on LH panel */
.legendTable {
  text-align: left;
  line-height: 0.9;
  color: #555;
  font: 11px Arial, Helvetica, sans-serif;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border-collapse: collapse;
  margin-right:7px;
  margin-left:7px;
}

/* Abundance code colors in the legend */
.circle{
  display:block;
  height:16px;
  width:16px;
  border-radius:50%;
  margin:auto;
  line-height:50px;
 }

#circle0{
  background-color:rgb(182, 182, 182); ; 
  border:5px solid rgb(182, 182, 182); ;
}
#circle1{
  background-color:rgb(177, 178, 233);  
  border:5px solid rgb(177, 178, 233); 
}
#circle2{
  background-color:rgb(242, 201, 252); 
  border:5px solid rgb(242, 201, 252); 
}

#circle3{
  background-color:rgb(250, 217, 6); 
  border:5px solid rgb(250, 217, 6);
}

#circle4{
  background-color:rgb(245, 139, 10); 
  border:5px solid rgb(245, 139, 10); 
}
#circle5{
  background-color:rgb(252, 68, 13); 
  border:5px solid rgb(252, 68, 13); 
}


.carousel-item > img { 
  width: 100%; 
  
}

/* Hints for dealing with modals:

If possible move the Modal into the <body> element
If possible have no position fixed, absolute or relative elements above the .modal
If possible use code to move the .modal to the <body> tag, and remove or move back when done
Explicitly override the .modal-backdrop and .modal-content zindex style
If all else fails, remove the background on .modal-background with display: none or the data-background=false attribute.* */