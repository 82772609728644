/* Styling for burger button (bars) and content of the right hand panel  - based on https://www.npmjs.com/package/react-burger-menu */

/* See Sidebar:js for styling of container for button and table of observations 
  See MyMap for height of Map*/

/* Position and sizing of burger button (bars) */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 36px;
    right:66px; 
    top: 30px;
    background-color: rgb(247, 246, 252);
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: blue;
    
    }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #4f00a9;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
    margin-right:40px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: blue;
  }
  
  /* Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles including color of the panel, padding; CONTROLS FONT SIZE OF THE TABLE */
  .bm-menu {
    background: rgba(175, 220, 238, 0.7);
    padding: 0.5em 0.5em 0.5em 0;
    font-size: 0.8em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for list of observations (items)*/
  .bm-item-list {
    overflow-y: scroll;
    color: #b8b7ad;
    padding: 0.5em 0.5em 0.5em 0;
  }
  
  /* Individual observation(item) including color of font when cursor is NOT hovering*/
  .bm-item {
    display: inline-block;
    color: #7a7474;
    margin-bottom: 10px;
    text-align: center;
    text-decoration: none;
    transition: color 0.2s;
  }
  
  /* color of font on hover */
  .bm-item:hover {
    color: #ffffff;
  }

  /* Styling of overlay */
  bm-overlay {
    background: rgba(0, 0, 0, 0);
  }

  /* Individual item */
.bm-item {
    display: inline-block;
  }

  

